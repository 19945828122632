import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlineIcon from '@material-ui/icons/LockOutline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import qs from 'query-string';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  reContainer: {
    alignItems: 'center',
  }
});

class RestorePassword extends React.Component {
  constructor(props) {
    super(props);
    const { location } = window;
    const token = qs.parse(location.search).token
    this.state = {
      token: token,
      email: '',
      password1: '',
      password2: '',
      inProgress: false,
      isFormHidden: false,
      text: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  verifyCallback(response) {
    this.setState({ gRrecaptchaRresponse: response })
  }
  handleSubmit(event) {
    event.preventDefault()
    const token = this.state.token
    this.setState({ inProgress: true })
    if (!token) {
      const data = { email: this.state.email }
      return axios.post('/api/user/reset-password', data)
        .then(() => this.setState({ isFormHidden: true, inProgress: false, text: 'Success. Please check your inbox.' }))
        .catch(err => {
          let message = ''
          if (err.response) {
            let data = err.response.data;
            if (data && data.errors && data.errors.error && data.errors.error.message) {
              message = data.errors.error.message
            }
          }
          this.setState({ inProgress: false, text: 'Failed. ' + message })
        });
    }
    const data = { user: { password: this.state.password1 } }
    return axios.put('/api/user', data, {headers: { Authorization: `Token ${token}` }})
      .then(() => this.setState({ isFormHidden: true, inProgress: false, text: 'Success. Return to AGU Brushy application.' }))
      .catch(err => {
        let message = ''
        if (err.response) {
          let data = err.response.data;
          if (data && data.errors && data.errors.error && data.errors.error.message) {
            message = data.errors.error.message
          }
        }
        this.setState({ inProgress: false, text: 'Failed. ' + message })
      });
  }

  render() {
    const { classes } = this.props;
    const token = this.state.token;
    const isFormHidden = this.state.isFormHidden;
    const title = token ? 'New Password' : 'Password Reminder';
    const button = token ? 'Set' : 'Send';

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="title">{title}</Typography>

          <Typography component="h2" variant="title">{this.state.text}</Typography>

          {this.state.inProgress && <CircularProgress className={classes.progress} />}
          <form className={classes.form} onSubmit={this.handleSubmit} action="/action_page.php" method="post">
            {(token && (!isFormHidden)) ? (
              <div>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input name="password1" type="password" id="password1" autoComplete="current-password"
                    onChange={this.handleChange} />
                </FormControl>
              {/*
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Confirm Password</InputLabel>
                  <Input name="password2" type="password" id="password2" autoComplete="current-password"
                    onChange={this.handleChange} />
                </FormControl>
                */}
              </div>
            ) : null}
            {(!token) ? (
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input name="email" type="email" id="email" autoComplete="current-email"
                  onChange={this.handleChange} />
              </FormControl>
            ) : null}
            {(!isFormHidden) ? (
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                {button}
              </Button>
            ) : null}
      {/*
            {true ? null : (
              <div style={{textAlign: 'center', marginTop: '25px'}}>
                <Recaptcha
                  sitekey={SITE_KEY}
                  render="explicit"
                  verifyCallback={this.verifyCallback}
                />
              </div>
            )}
            */}
          </form>
          <br />
        </Paper>
      </main>
    );
  }
}

RestorePassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RestorePassword);
