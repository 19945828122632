import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK } from 'react-admin';
import { API_URL } from './config';
const LOGIN_URL = API_URL + '/users/login'
const GET_USER_URL = API_URL + '/user'
const TOKEN = 'token'

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const email = `${username}@example.com`;
        const request = new Request(LOGIN_URL, {
            method: 'POST',
            body: JSON.stringify({ user: { email, password }}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ user }) => {
                localStorage.setItem(TOKEN, user.token);
            });
    }
    if (type === AUTH_LOGOUT) {
      localStorage.removeItem(TOKEN)
      return Promise.resolve()
    }
    if (type === AUTH_CHECK) {
      const token = localStorage.getItem(TOKEN)
      if (token === null) { return Promise.reject() }
      const getUserRequest = new Request(GET_USER_URL, {
        method: 'GET',
        headers: new Headers({ 'Authorization': `Token ${token}`, 'Content-Type': 'application/json' })
      })
      return fetch(getUserRequest).then(response => {
        if (response.status !== 200) {
          return Promise.reject(response.statusText)
        }
        return Promise.resolve()
      })
    }
    return Promise.resolve();
}
