import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Datagrid,
  Edit,
  // EmailField,
  ImageField,
  List,
  NumberInput,
  NumberField,
  TextField,
  DateField,
  Pagination,
  ReferenceInput,
  ReferenceField,
  ReferenceManyField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleFormIterator,
  Tab,
  TabbedShowLayout,
  TextInput,
} from 'react-admin';

const KidPagination = props => <Pagination rowsPerPageOptions={[10, 100, 1000]} {...props} />

export const KidList = props => (
      <List {...props} pagination={<KidPagination />}>
          <Datagrid rowClick="show">
	      <TextField source="name" />
	      <ImageField source="photo" />
	      <DateField source="birthday" />
	      <TextField source="gender" />
	      <NumberField source="pieceNumber" />
	      <DateField source="createdAt" />
	      <DateField source="updatedAt" />
	      <ReferenceField source="userId" reference="users"><TextField source="email" /></ReferenceField>
	      <TextField source="hand" />
          <TextField source="toothbrushMac" />
          <TextField source="toothbrushUuid" />
        </Datagrid>
      </List>
);

export const KidEdit = props => (
      <Edit {...props}>
          <SimpleForm>
              <TextInput source="id" />
              <TextInput source="name" />
              <NumberInput source="age" />
              <NumberInput source="profile.avgQuality" />
              <DateInput source="createdAt" />
              <DateInput source="updatedAt" />
              <ReferenceInput source="userId" reference="users">
                <SelectInput optionText="email" />
              </ReferenceInput>
              <ArrayInput source="achievements">
                <SimpleFormIterator>
                  {/* <TextInput source="id" /> */}
                  <ImageField source="imageUrl" title="Sticker" />
                  <BooleanInput source="isCompletelyOpen" />
                  <TextInput source="openParts" />
                  <DateInput source="createdAt" />
                  <DateInput source="updatedAt" />
                </SimpleFormIterator>
              </ArrayInput>
          </SimpleForm>
      </Edit>
);

export const KidShow = props => (
    <Show {...props}>
	<TabbedShowLayout>
	  <Tab label="Summary">

            <TextField source="name" />
	    <ImageField source="image" />
	    <DateField source="birthday" />
	    <TextField source="gender" />
	    <TextField source="hand" />
	    <NumberField source="pieceNumber" />
	    <ImageField source="photo" />
	    <DateField source="createdAt" />
	    <DateField source="updatedAt" />
	    <ReferenceField source="userId" reference="users">
		<TextField source="email" />
	    </ReferenceField>
	  </Tab>
	  <Tab label="Brushings">
	      <ReferenceManyField
	        addLabel={false}
	        reference="kids-brushings"
	        target="kid"
	        sort={{ field: 'time', order: 'DESC' }}
	      >
          <Datagrid>
            <TextField source="percent" />
            <TextField source="stars" />
            <DateField source="time" showTime locales="ru-RU" />
            <TextField source="timeOfDay" />
            <TextField source="zones" />
          </Datagrid>
	      </ReferenceManyField>
	  </Tab>
	</TabbedShowLayout>
    </Show>
);
