import React from 'react';
import { Route } from 'react-router-dom';
import ResetPassword from './reset-password/ResetPassword';
import ChangePassword from './change-password/ChangePassword';
import ConfirmEmail from './confirm-email/ConfirmEmail';

export default [
  <Route exact path="/mobile-hook/reset-password" component={ResetPassword} noLayout />,
  <Route exact path="/mobile-hook/confirm-email" component={ConfirmEmail} noLayout />,
  <Route exact path="/mobile-hook/change-password" component={ChangePassword} noLayout />,
];
