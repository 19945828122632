import React from 'react';
import {
  ArrayField,
  BooleanInput,
  DateInput,
  Edit,
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  DateField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
} from 'react-admin';

const UserPagination = props => <Pagination rowsPerPageOptions={[10, 50, 100]} {...props} />

export const UserList = props => (
      <List {...props} pagination={<UserPagination />}>
          <Datagrid rowClick="show">
              <TextField source="username" />
              <EmailField source="email" />
              <BooleanField source="isActive" />
              <DateField source="createdAt" />
              <DateField source="updatedAt" />
          </Datagrid>
      </List>
);

export const UserEdit = props => (
      <Edit {...props}>
          <SimpleForm>
              <TextInput source="username" />
              <TextInput source="email" />
              <BooleanInput source="isActive" />

              <ArrayField source="kids">
                <Datagrid>
                  <TextField source="name" />
                </Datagrid>
              </ArrayField>
              <ReferenceManyField
                addLabel={false}
                reference="kids"
                target="userId"
              >
                <Datagrid>
                  <TextField source="name" />
                  <ShowButton />
                </Datagrid>
              </ReferenceManyField>
 
              <DateInput source="createdAt" />
              <DateInput source="updatedAt" />
          </SimpleForm>
      </Edit>
);

export const UserShow = props => (
      <Show {...props}>
          <SimpleShowLayout>
              <TextField source="username" />
              <EmailField source="email" />
              <BooleanField source="isActive" />
              <DateField source="createdAt" />
              <DateField source="updatedAt" />
              <ArrayField source="kids">
                  <Datagrid>
                      <TextField source="name" />
                  </Datagrid>
              </ArrayField>
              <ReferenceManyField
                addLabel={false}
                reference="kids"
                target="userId"
                sort={{ field: 'created_at', order: 'DESC' }}
              >
                <Datagrid>
                  <TextField source="name" />
                  <ShowButton />
                </Datagrid>
              </ReferenceManyField>
          </SimpleShowLayout>
      </Show>
);
