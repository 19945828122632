import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlineIcon from '@material-ui/icons/LockOutline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  reContainer: {
    alignItems: 'center',
  }
});

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      old_password: '',
      new_password: '',
      inProgress: false,
      isFormHidden: false,
      text: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  verifyCallback(response) {
    this.setState({ gRrecaptchaRresponse: response })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ inProgress: true })

    const data = {
      email: this.state.email,
      old_password: this.state.old_password,
      new_password: this.state.new_password,
    }

    return axios.post('/api/user/change-password', data)
      .then(() => this.setState({ isFormHidden: true, inProgress: false, text: 'New password set. Return to AGU Brushy application' }))
      .catch(err => {
        let message = 'Error!'
        if (err.response) {
          let data = err.response.data;
          if (data && data.status && data.status === 'error' && data.message) {
            message = data.message
          }
          else if (data && data.error) {
            message = data.error
          }
        }
        this.setState({ inProgress: false, text: 'Failed. ' + message })
      });
  }

  render() {
    const { classes } = this.props;
    const isFormHidden = this.state.isFormHidden;
    const title = 'Change Password';
    const button = 'Set';

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="title">{title}</Typography>

          <Typography component="h2" variant="title">{this.state.text}</Typography>

          {this.state.inProgress && <CircularProgress className={classes.progress} />}

          {(!isFormHidden) && (
            <form className={classes.form} onSubmit={this.handleSubmit} action="/action_page.php" method="post">

              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input name="email" type="email" id="email" autoComplete="current-email"
                  onChange={this.handleChange} />
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="old_password">Current Password</InputLabel>
                <Input name="old_password" type="password" id="old_password" autoComplete="old_password"
                  onChange={this.handleChange} />
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="new_password">New Password</InputLabel>
                <Input name="new_password" type="password" id="new_password" autoComplete="new_password"
                  onChange={this.handleChange} />
              </FormControl>

              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                {button}
              </Button>
            </form>
          )}
          <br />
        </Paper>
      </main>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangePassword);
