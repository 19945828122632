import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
      main: {
        background: '#bed82c',
        backgroundImage: 'none',
      },
      // avatar: {
      //           background: 'url(//cdn.example.com/background.jpg)',
      //           backgroundRepeat: 'no-repeat',
      //           backgroundSize: 'contain',
      //           height: 80,
      //       },
      // icon: { display: 'none' },
});

const CustomLoginForm = withStyles({
      button: { background: '#bed82c' },
})(LoginForm);

const CustomLoginPage = props => (
      <Login
          backgroundImage="none"
          loginForm={<CustomLoginForm />}
          {...props}
      />
);

export default withStyles(styles)(CustomLoginPage);
