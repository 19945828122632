import React from 'react';
import InsertEmoticonOutlined from "@material-ui/icons/InsertEmoticon";
import axios from 'axios';
import {
  Admin,
  Resource,
  fetchUtils,
  // ListGuesser,
  // EditGuesser,
  // ShowGuesser,
} from 'react-admin';
import LoginPage from './LoginPage';
import jsonServerProvider from 'ra-data-json-server';
// import { FileList, FileUpload } from './files';
// import { StickerList, StickerUpload } from './stickers';
import { UserList, UserEdit, UserShow } from './users';
import { KidList, KidEdit, KidShow } from './kids';
import authProvider from './authProvider';
import customRoutes from './customRoutes';
import { API_URL } from './config';

const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('token');
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    return fetchUtils.fetchJson(url, options);
}

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
  if (type === 'CREATE' && (resource === 'files' || resource === 'stickers')) {
    // console.log('CREATE: files')
    // console.log('params:', params)
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.set('serialNumber', params.data.serialNumber)
    formData.set('language', params.data.language)
    formData.append('file', params.data.files.rawFile)
    let config = {headers: {'content-type': 'multipart/form-data'}}
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return axios.post(`${API_URL}/${resource}`, formData, config)
    // return requestHandler(type, resource, {...params, data: formData})
  }
  return requestHandler(type, resource, params);
}

const dataProvider = jsonServerProvider(API_URL, httpClient);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

const App = () => (
  <Admin customRoutes={customRoutes} dataProvider={uploadCapableDataProvider} authProvider={authProvider}
    loginPage={LoginPage}
  >
  {/*
    <Resource name='files' list={FileList} create={FileUpload} />
    <Resource name='stickers' list={StickerList} create={StickerUpload} />
  */}
    <Resource name='users' list={UserList} edit={UserEdit} show={UserShow} />
    <Resource name='kids' list={KidList} edit={KidEdit} show={KidShow} icon={InsertEmoticonOutlined} />
    <Resource name="kids-brushings" />
    {/*<Resource name='kids' list={KidList} edit={KidEdit} show={KidShow} icon={InsertEmoticonOutlined} />*/}
  </Admin>
);

export default App;
