import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'query-string';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  reContainer: {
    alignItems: 'center',
  }
});

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inProgress: true, text: 'In Progress ...' };
  }
  componentDidMount() {
    const { location } = window;
    const token = qs.parse(location.search).token
    axios('/api/user/activate?token=' + token)
      .then(() => {
        this.setState({ inProgress: false, text: 'Success. Return to AGU Brushy application.' })
      })
      .catch(err => {
        let message = ''
        if (err.response) {
          let data = err.response.data;
          if (data && data.errors && data.errors.error && data.errors.error.message) {
            message = data.errors.error.message
          }
        }
        this.setState({ inProgress: false, text: 'Failed. ' + message })
      });
  }

  render() {
    const { classes } = this.props;
    const title = 'Confirm email';

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="title">{title}</Typography>
          <br />
          <Typography component="h2" variant="title">{this.state.text}</Typography>
          {this.state.inProgress && <CircularProgress className={classes.progress} />}
          <br />
        </Paper>
      </main>
    );
  }
}

ConfirmEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmEmail);
